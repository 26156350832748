import { ItemDescription } from './Item';
import { ChartOptions } from 'chart.js';

export interface ISnowDepth {
  [key: string]: ISnowDepthObject;
}

export interface ISnowDepthObject {
  snowDepthThisYear: ISnowDepthValue[];
  snowDepthLastYear: ISnowDepthValue[];
  currentValue: ILatestSnowDepth;
}

export interface ISnowDepthValue {
  date: Date;
  value: number;
  quality: string;
}

export interface ILatestSnowDepth {
  date: Date;
  value: number;
  unit: string;
  description: ItemDescription;
}

export enum SnowDepthObject {
  SpencersCreek = 'Spencers Creek',
  DeepCreek = 'Deep Creek',
  ThreeMileDam = 'Three Mile Dam',
}

export const SnowDepthObjectOrder = [
  SnowDepthObject.SpencersCreek,
  SnowDepthObject.DeepCreek,
  SnowDepthObject.ThreeMileDam,
];

export interface ISnowDepthJson {
  snowyhydro: {
    level: ISnowDepthJsonItem[];
  };
}

export interface ISnowDepthJsonItem {
  $: {
    date: string;
  };
  snow: ISnowDepthJsonItemSnow[];
}

export interface ISnowDepthJsonItemSnow {
  $: {
    name: string;
    dataTimestamp: string;
    quality: string;
  };
  _: string;
}

export interface IConvertedSnowDepth {
  name: string;
  date: Date;
  quality: string;
  value: number;
}

export interface IGroupedSnowDepths {
  [key: string]: ISnowDepthValue[];
}

export const snowDepthHistoryChartOptions: ChartOptions = {
  parsing: {
    xAxisKey: 'date',
    yAxisKey: 'value',
  },
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 200,
      ticks: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        callback: (value: number) => `${value}cm`,
      },
    },
    x: {
      min: new Date(new Date().getFullYear(), 0, 1).getTime(),
      max: new Date(new Date().getFullYear(), 10, 31).getTime(),
      type: 'time',
      time: {
        unit: 'month',
        displayFormats: {
          month: 'MMM',
        },
        tooltipFormat: 'MMM dd',
      },
      ticks: {},
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

export const SnowDepthThisYearChartDataConfig = (year: number) => ({
  borderColor: 'rgba(80, 169, 99, 1)',
  backgroundColor: 'rgba(80, 169, 99, 0.2)',
  fill: true,
  label: year.toString(),
});

export const SnowDepthLastYearChartDataConfig = (year: number) => ({
  borderColor: 'rgba(51, 150, 197, 1)',
  backgroundColor: 'rgba(51, 150, 197, 0.2)',
  fill: true,
  label: year.toString(),
});
