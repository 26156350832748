import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../shared/ui/header/header.component';
import { IonContent, IonicModule } from '@ionic/angular';
import { ContentfulService } from '../contentful/contentful.service';
import { InfoEntries, IInfoContent } from '../contentful/contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { environment } from '../../environments/environment';

@Component({
  selector: 'snowylive-info',
  standalone: true,
  imports: [CommonModule, HeaderComponent, IonicModule],
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) content!: IonContent;

  private contentfulService = inject(ContentfulService);
  private cdr = inject(ChangeDetectorRef);
  private infoContentId = InfoEntries.infoPage;
  protected readonly version = environment.version;
  infoContent!: IInfoContent;
  infoRichText!: string;

  async ngOnInit() {
    this.infoContent = (
      await this.contentfulService.getEntryById(this.infoContentId)
    ).fields;
    this.infoRichText = documentToHtmlString(this.infoContent.content);
    this.cdr.detectChanges();
  }

  backToTop = async () => {
    await this.content.scrollToTop(500);
  };
}
