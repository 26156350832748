import { ToastController } from '@ionic/angular';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastController = inject(ToastController);

  async presentToast(
    message: string,
    position: 'top' | 'middle' | 'bottom' = 'bottom',
    duration = 1500
  ) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position,
    });

    await toast.present();
  }
}
