import { inject, Injectable } from '@angular/core';
import { complement, isNil, isNilOrEmpty } from '../ramda-functions';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alertController = inject(AlertController);

  public async presentDoubleActionAlert(
    header: string,
    message: string,
    yesHandler: () => void,
    yesText = 'Ok',
    noHandler?: () => void,
    runNoHandlerOnDismiss = false
  ) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: yesText,
          handler: yesHandler,
        },
        {
          text: 'Cancel',
          ...(isNil(noHandler) ? { role: 'cancel' } : { handler: noHandler }),
          cssClass: 'danger-alert-button',
        },
      ],
    });

    if (runNoHandlerOnDismiss && complement(isNilOrEmpty(noHandler))) {
      alert.onWillDismiss().then(() => {
        noHandler?.();
      });
    }

    return alert.present();
  }

  public async presentSingleActionAlert(
    header: string,
    message: string,
    yesHandler: () => void,
    yesText = 'Ok'
  ) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: yesText,
          handler: yesHandler,
        },
      ],
    });

    return alert.present();
  }
}
