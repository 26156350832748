import { inject, Injectable } from '@angular/core';
import { ContentfulService } from '../contentful/contentful.service';
import { IPublicAccess, publicAccess } from '../models/PublicAccess';
import { IMenuItem } from '../models/Item';
import { Document } from '@contentful/rich-text-types';
import { equals } from '../ramda-functions';

@Injectable({
  providedIn: 'root',
})
export class PublicAccessService {
  private contentfulService = inject(ContentfulService);

  private publicAccessContents = publicAccess;

  getPublicAccessContents = () => this.publicAccessContents;

  setPublicAccessContents = (publicAccessContents: IPublicAccess[]) => {
    this.publicAccessContents = publicAccessContents;
  };

  getPublicAccessContent = (name: string) =>
    this.publicAccessContents.find((publicAccessContent) =>
      equals(publicAccessContent.name, name)
    );

  initPublicAccessContents = async (publicAccessContents: IPublicAccess[]) => {
    try {
      const publicAccessContentsWithContent =
        await this.loadPublicAccessContents(publicAccessContents);
      this.setPublicAccessContents(publicAccessContentsWithContent);
    } catch (error) {
      throw new Error(`Error loading public access contents: ${error}`);
    }
  };

  private loadPublicAccessContents = async (contents: IPublicAccess[]) => {
    return Promise.all(
      contents.map(async (content) => {
        const publicAccessContent = (
          await this.contentfulService.getEntryById(content.entryId)
        ).fields;
        return {
          ...content,
          content: publicAccessContent.content as Document,
        };
      })
    );
  };

  generateMenuItems = (publicAccessContents: IPublicAccess[]): IMenuItem[] =>
    publicAccessContents.map(({ name, href, icon }) => ({
      name,
      href,
      icon,
    }));
}
