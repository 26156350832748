export enum WaterReleaseDam {
  Jindabyne = 'Jindabyne Dam',
  Tantangara = 'Tantangara Dam',
  Khancoban = 'Khancoban Dam',
}

export interface IWaterRelease {
  [key: string]: IWaterReleaseItem | ICurrentReleaseItem;
}

export interface IWaterReleaseItem {
  currentValue: IWaterReleaseValue;
  values: IWaterReleaseValue[];
}

export interface ICurrentReleaseItem {
  value: number;
  date: Date;
  growthStatus: string;
  unit: string;
}

export interface IWaterReleaseValue {
  date: Date;
  value: number;
  unit: string;
}

export interface IWaterReleaseJson {
  snowyhydro: IWaterReleaseJsonItem | ICurrentWaterReleaseJsonItem;
}

export interface IWaterReleaseJsonItem {
  releases: IWaterReleaseJsonItemRelease[];
}

export interface ICurrentWaterReleaseJsonItem {
  dam: ICurrentWaterReleaseJsonItemDam[];
}

export interface IWaterReleaseJsonItemRelease {
  $: {
    dam: string;
    date: string;
  };
  release: IReleaseValue[];
}

export interface IReleaseValue {
  $: {
    date: string;
  };
  _: string;
}

export interface ICurrentWaterReleaseJsonItemDam {
  $: {
    name: string;
    flowRateChange: string;
    dataTimeStamp: string;
  };
  _: string;
}
