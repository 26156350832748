import { inject, Injectable } from '@angular/core';
import { DBService } from '../cache/db.service';
import { environment } from '../../environments/environment';
import { DataFetchType, DBTableName } from '../cache/data-config';
import {
  ICurrentReleaseItem,
  ICurrentWaterReleaseJsonItem,
  ICurrentWaterReleaseJsonItemDam,
  IReleaseValue,
  IWaterRelease,
  IWaterReleaseItem,
  IWaterReleaseJson,
  IWaterReleaseJsonItem,
  IWaterReleaseJsonItemRelease,
  IWaterReleaseValue,
  WaterReleaseDam,
} from '../models/WaterRelease';
import { equals, isNil } from '../ramda-functions';
import { GrowthStatus, TileType } from '../models/Item';
import { Store } from '@ngrx/store';
import { FavouritesState } from '../favourites/favourites.reducer';
import {
  FavouriteHref,
  FavouriteTileIcon,
  IFavouritesEntity,
} from '../favourites/favourites.models';
import { updateFavourites } from '../favourites/favourites.actions';
import { ToastService } from '../shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class WaterReleasesService {
  private dbService = inject(DBService);
  private favouriteStore = inject(Store<FavouritesState>);
  private toastService = inject(ToastService);

  private waterReleases!: IWaterRelease;

  setWaterReleases = (waterReleases: IWaterRelease) => {
    this.waterReleases = waterReleases;
  };

  getWaterReleases = () => this.waterReleases;

  private generateUrl = (fetchType: DataFetchType) =>
    `${environment.dataUrl}/${fetchType}.xml`;

  loadWaterReleasesData = async () => {
    if (await this.dbService.isDataValid(DBTableName.WaterReleases)) {
      return this.dbService.get(DBTableName.WaterReleases);
    }
    return this.initWaterReleasesData();
  };

  private fetchData = async (fetchType: DataFetchType) => {
    const url = this.generateUrl(fetchType);
    try {
      return (await this.dbService.fetchData(url)) as IWaterReleaseJson;
    } catch (e) {
      const error = e as Error;
      if (equals(error.name, 'DataNotFoundError')) {
        await this.toastService.presentToast(
          `No water release data found for ${fetchType}.`
        );
        return undefined;
      } else {
        throw error;
      }
    }
  };

  private initWaterReleasesData = async (): Promise<IWaterRelease> => {
    try {
      const jintangaraData = await this.fetchData(DataFetchType.Jindabyne);
      const tantangaraData = await this.fetchData(DataFetchType.Tantangara);
      const khancobanData = await this.fetchData(DataFetchType.Khancoban);

      const waterReleases: IWaterRelease = {
        ...this.convertData(WaterReleaseDam.Jindabyne, jintangaraData),
        ...this.convertData(WaterReleaseDam.Tantangara, tantangaraData),
        ...this.convertData(WaterReleaseDam.Khancoban, khancobanData),
      };

      await this.updateWaterReleasesData(waterReleases);
      return waterReleases;
    } catch (error) {
      if (await this.dbService.isDataExisted(DBTableName.WaterReleases)) {
        return this.dbService.get(DBTableName.WaterReleases);
      }
      throw error;
    }
  };

  private updateWaterReleasesData = async (data: IWaterRelease) => {
    await this.dbService.set(DBTableName.WaterReleases, data);
    await this.dbService.updateCacheLastModified(DBTableName.WaterReleases);
    this.updateFavourites(data);
  };

  private updateFavourites = (data: IWaterRelease) => {
    const favourites: IFavouritesEntity[] = Object.entries(data).map(
      ([id, waterRelease]) => ({
        id,
        icon: FavouriteTileIcon.waterRelease,
        type: equals(id, WaterReleaseDam.Khancoban)
          ? TileType.CurrentRelease
          : TileType.WaterRelease,
        href: FavouriteHref.waterRelease,
        value: equals(id, WaterReleaseDam.Khancoban)
          ? (waterRelease as ICurrentReleaseItem).value
          : (waterRelease as IWaterReleaseItem).currentValue.value,
        unit: (waterRelease as ICurrentReleaseItem).unit,
        growthStatus: equals(id, WaterReleaseDam.Khancoban)
          ? (waterRelease as ICurrentReleaseItem).growthStatus
          : undefined,
      })
    );

    this.favouriteStore.dispatch(updateFavourites({ favourites }));
  };

  private convertData = (
    dam: WaterReleaseDam,
    rawJsonData?: IWaterReleaseJson
  ): IWaterRelease => {
    if (isNil(rawJsonData) || isNil(rawJsonData.snowyhydro)) {
      return {};
    }
    return equals(dam, WaterReleaseDam.Khancoban)
      ? this.convertCurrentReleaseData(
          (rawJsonData.snowyhydro as ICurrentWaterReleaseJsonItem).dam,
          dam
        )
      : this.convertReleaseData(
          (rawJsonData.snowyhydro as IWaterReleaseJsonItem).releases,
          dam
        );
  };

  private convertReleaseData = (
    jsonReleaseItems: IWaterReleaseJsonItemRelease[],
    dam: WaterReleaseDam
  ): IWaterRelease => {
    const releaseData = jsonReleaseItems[0].release;
    const currentReleaseData: IWaterReleaseValue = {
      value: parseFloat(releaseData[0]._),
      date: new Date(jsonReleaseItems[0].$.date),
      unit: this.generateUnit(dam),
    };
    const releaseSchedule: IWaterReleaseValue[] = releaseData.map(
      (release: IReleaseValue) => ({
        value: parseFloat(release._),
        date: new Date(release.$.date),
        unit: this.generateUnit(dam),
      })
    );
    return {
      [dam]: {
        currentValue: currentReleaseData,
        values: releaseSchedule,
      },
    };
  };

  private convertCurrentReleaseData = (
    currentReleases: ICurrentWaterReleaseJsonItemDam[],
    dam: WaterReleaseDam
  ): IWaterRelease => {
    const currentReleaseData = currentReleases[0];
    return {
      [dam]: {
        value: parseFloat(currentReleaseData._),
        date: new Date(currentReleaseData.$.dataTimeStamp),
        growthStatus: this.convertFlowRateChange(
          currentReleaseData.$.flowRateChange
        ),
        unit: this.generateUnit(dam),
      },
    };
  };

  private convertFlowRateChange = (flowRateChange: string) => {
    return equals(flowRateChange, 'rising')
      ? GrowthStatus.GROWING
      : equals(flowRateChange, 'falling')
      ? GrowthStatus.FALLING
      : GrowthStatus.STABLE;
  };

  private generateUnit = (dam: WaterReleaseDam) =>
    equals(dam, WaterReleaseDam.Khancoban) ? 'cumecs' : 'ML';
}
