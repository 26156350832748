import { Route } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { ContentfulEffects } from './contentful/contentful.effects';
import { InfoComponent } from './info/info.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FavouritesEffects } from './favourites/favourites.effects';

export const appRoutes: Route[] = [
  {
    path: 'sync',
    loadChildren: () =>
      import('./sync/sync.routes').then((mod) => mod.SyncRoutes),
    providers: [provideEffects(ContentfulEffects)],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.routes').then((mod) => mod.homeRoutes),
    providers: [provideEffects(ContentfulEffects, FavouritesEffects)],
  },
  {
    path: 'lake-levels',
    loadChildren: () =>
      import('./lake-levels/lake-levels.routes').then(
        (mod) => mod.LakeLevelsRoutes
      ),
  },
  {
    path: 'snow-depths',
    loadChildren: () =>
      import('./snow-depths/snow-depths.routes').then(
        (mod) => mod.SnowDepthsRoutes
      ),
  },
  {
    path: 'water-releases',
    loadChildren: () =>
      import('./water-releases/water-releases.routes').then(
        (mod) => mod.WaterReleasesRoutes
      ),
  },
  {
    path: 'info',
    component: InfoComponent,
  },
  {
    path: 'public-access',
    loadChildren: () =>
      import('./public-access/public-access.routes').then(
        (mod) => mod.publicAccessRoutes
      ),
  },
  {
    path: 'visit-the-scheme',
    loadChildren: () =>
      import('./visit-the-scheme/visit-the-scheme.routes').then(
        (mod) => mod.VisitTheSchemeRoutes
      ),
  },
  {
    path: 'favourites',
    loadChildren: () =>
      import('./favourites/favourites.routes').then(
        (mod) => mod.favouritesRoutes
      ),
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
];
