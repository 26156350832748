import { Injectable, inject } from '@angular/core';
import {
  createEffect,
  Actions,
  ofType,
  ROOT_EFFECTS_INIT,
  concatLatestFrom,
} from '@ngrx/effects';
import {
  switchMap,
  catchError,
  of,
  tap,
  withLatestFrom,
  map,
  from,
} from 'rxjs';
import { ContentfulService } from './contentful.service';
import { isNil, isNilOrEmpty } from '../ramda-functions';
import {
  checkUpdateFailure,
  nextSyncTokenUpdate,
  retry,
  switchContentfulContext,
  syncAssets,
  syncAssetsFailure,
  syncAssetsSuccess,
  syncEntries,
  syncEntriesFailure,
  syncEntriesSuccess,
  syncSkipped,
} from './contentful.actions';
import { IContentfulState } from './contentful.reducer';
import { Store } from '@ngrx/store';
import { selectNextSyncToken } from './contentful.selectors';
import { HomeService } from '../home/home.service';
import { MobileUpdateService } from '../update/mobile/mobile-update.service';

@Injectable()
export class ContentfulEffects {
  private actions$ = inject(Actions);
  private contentfulService = inject(ContentfulService);
  private contentfulStore = inject(Store<IContentfulState>);
  private homeService = inject(HomeService);
  private mobileUpdateService = inject(MobileUpdateService);

  checkUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT, retry),
      tap(() => this.contentfulService.preventScreenSleep()),
      tap(() => this.mobileUpdateService.checkAppStoreUpdates()),
      switchMap(() => this.contentfulService.checkRemoteUpdate()),
      concatLatestFrom(() =>
        this.contentfulStore
          .select(selectNextSyncToken)
          .pipe(map((nextSyncToken) => isNilOrEmpty(nextSyncToken)))
      ),
      switchMap(([syncCollection, firstLaunch]) =>
        from(
          this.contentfulService.presentNotificationIfHasChanges(
            syncCollection,
            firstLaunch
          )
        ).pipe(
          map(() => syncEntries({ syncCollection })),
          catchError(() =>
            of(
              firstLaunch
                ? checkUpdateFailure({ error: 'Sync Entries Error' })
                : syncSkipped()
            )
          )
        )
      ),
      catchError((err) =>
        of(checkUpdateFailure({ error: err?.message || 'Sync Entries Error' }))
      )
    );
  });

  changeStage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(switchContentfulContext),
      switchMap(() => this.contentfulService.clearCache()),
      map(() => retry())
    );
  });

  syncEntries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(syncEntries),
      switchMap(({ syncCollection }) =>
        this.contentfulService.syncEntries(syncCollection).pipe(
          map((syncCollection) => syncEntriesSuccess({ syncCollection })),
          catchError((err) =>
            of(
              syncEntriesFailure({
                error: err?.message || 'Sync Entries Error',
              })
            )
          )
        )
      )
    );
  });

  syncEntriesSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(syncEntriesSuccess),
      map((syncCollection) => syncAssets(syncCollection))
    );
  });

  syncAssets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(syncAssets),
      switchMap(({ syncCollection }) =>
        this.contentfulService.syncAssets(syncCollection).pipe(
          map((nextSyncToken) => syncAssetsSuccess({ nextSyncToken })),
          catchError((err) =>
            of(
              syncAssetsFailure({
                error: err?.message || 'Sync Assets Error',
              })
            )
          )
        )
      )
    );
  });

  syncAssetsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(syncAssetsSuccess),
        tap(() => this.contentfulService.allowScreenSleep()),
        tap(() => this.homeService.loadContentfulData()),
        map(({ nextSyncToken }) =>
          this.contentfulService.updateNextToken(nextSyncToken)
        )
      );
    },
    { dispatch: false }
  );
}
