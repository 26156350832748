import { createAction, props } from '@ngrx/store';
import { ContentfulContext, ISyncCollection } from './contentful';

export const retry = createAction('[Contentful] Retry');

export const checkUpdateFailure = createAction(
  '[Contentful] Check Update Failure',
  props<{ error: any }>()
);

export const syncEntries = createAction(
  '[Contentful] Sync Entries',
  props<{ syncCollection: ISyncCollection }>()
);

export const syncEntriesSuccess = createAction(
  '[Contentful] Sync Entries Success',
  props<{ syncCollection: ISyncCollection }>()
);

export const syncSkipped = createAction('[Contentful] Sync Skipped');

export const syncEntriesFailure = createAction(
  '[Contentful] Sync Entries Failure',
  props<{ error: any }>()
);

export const syncAssets = createAction(
  '[Contentful] Sync Assets',
  props<{ syncCollection: ISyncCollection }>()
);

export const assetsDownloaded = createAction('[Contentful] Assets Downloaded');

export const syncAssetsSuccess = createAction(
  '[Contentful] Sync Assets Success',
  props<{ nextSyncToken: string }>()
);

export const syncAssetsFailure = createAction(
  '[Contentful] Sync Assets Failure',
  props<{ error: any }>()
);

export const clearCache = createAction('[Contentful] Clear Cache');

export const updateEntriesToCache = createAction(
  '[Contentful] Update Entries To Download',
  props<{ entriesToCache: number }>()
);

export const updateEntriesCached = createAction(
  '[Contentful] Update Entries Downloaded'
);

export const updateAssetsToCache = createAction(
  '[Contentful] Update Assets To Download',
  props<{ assetsToCache: number }>()
);

export const updateAssetsDownloaded = createAction(
  '[Contentful] Update Assets Downloaded'
);

export const updateAssetsCached = createAction(
  '[Contentful] Update Assets Cached'
);

export const nextSyncTokenUpdate = createAction(
  '[Contentful] Next Sync Token Update',
  props<{ nextSyncToken: string }>()
);

export const clearNextSyncToken = createAction(
  '[Contentful] Clear Next Sync Token'
);

export const switchContentfulContext = createAction(
  '[Contentful] Switch Contentful Context',
  props<{ contentfulContext: ContentfulContext }>()
);
