import { inject, Injectable } from '@angular/core';
import { AppMetadataEntries, IAppMetadata } from '../../contentful/contentful';
import { ContentfulService } from '../../contentful/contentful.service';
import { not } from '../../ramda-functions';
import { environment } from '../../../environments/environment';
import { isVersionLessThan } from '../update.utils';
import { PlatformService } from '../../shared/services/platform.service';
import { AlertService } from '../../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class MobileUpdateService {
  private contentfulService = inject(ContentfulService);
  private platformService = inject(PlatformService);
  private alertService = inject(AlertService);

  public getLatestVersionFromContentful = async () => {
    try {
      const content: IAppMetadata = (
        await this.contentfulService.getEntryById(
          AppMetadataEntries.LatestVersion
        )
      ).fields;

      return content.value;
    } catch (e) {
      console.error(e);
      // anchor value to 0.0.0 if contentful is down, to prevent showing update alert
      return '0.0.0';
    }
  };

  public checkAppStoreUpdates = async () => {
    if (not(this.platformService.isMobile())) {
      // no need to check for updates in web app, it is handled by pwa update services
      return;
    }
    const latestVersion = await this.getLatestVersionFromContentful();
    const currentVersion = environment.version;

    if (isVersionLessThan(currentVersion, latestVersion)) {
      await this.alertService.presentSingleActionAlert(
        'App Update Available',
        'A new version is available in App Store. Please update the app to get the latest content.',
        async () => {}
      );
    }
  };
}
