import { Injectable, inject } from '@angular/core';
import { fromEvent, map, merge, Observable, startWith } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  private platform = inject(Platform);

  public isPWA(): boolean {
    return this.platform.is('pwa');
  }

  public isIOS(): boolean {
    return this.platform.is('ios');
  }

  public isAndroid(): boolean {
    return this.platform.is('android');
  }

  public isMobile(): boolean {
    return !this.platform.is('desktop');
  }

  public isMobileWeb(): boolean {
    return this.platform.is('mobileweb');
  }

  public isIosPwa(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  public isOffline$ = (): Observable<boolean> => {
    const online$ = fromEvent(window, 'online').pipe(map(() => false));
    const offline$ = fromEvent(window, 'offline').pipe(map(() => true));
    return merge(online$, offline$).pipe(startWith(!navigator.onLine));
  };
}
