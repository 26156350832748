import {
  DescriptionEntries,
  IDescriptionContent,
} from '../contentful/contentful';
import { Document } from '@contentful/rich-text-types';

export enum DescriptionTitle {
  Favourites = 'favourites',
  VisitTheScheme = 'visitTheScheme',
  PublicAccess = 'publicAccess',
}

export interface IDescription {
  title: DescriptionTitle;
  entryId: string;
  content?: Document;
}

export const Descriptions: IDescription[] = [
  {
    title: DescriptionTitle.Favourites,
    entryId: DescriptionEntries.Favourites,
  },
  {
    title: DescriptionTitle.VisitTheScheme,
    entryId: DescriptionEntries.VisitTheScheme,
  },
  {
    title: DescriptionTitle.PublicAccess,
    entryId: DescriptionEntries.PublicAccess,
  },
];
