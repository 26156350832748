import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FAVOURITES_FEATURE_KEY,
  FavouritesState,
  favouritesAdapter,
} from './favourites.reducer';

// Lookup the 'Favourites' feature state managed by NgRx
export const selectFavouritesState = createFeatureSelector<FavouritesState>(
  FAVOURITES_FEATURE_KEY
);

const { selectAll, selectEntities } = favouritesAdapter.getSelectors();

export const selectFavouritesError = createSelector(
  selectFavouritesState,
  (state: FavouritesState) => state.error
);

export const selectAllFavourites = createSelector(
  selectFavouritesState,
  (state: FavouritesState) => selectAll(state)
);

export const selectFavouriteById = (id: string) =>
  createSelector(selectAllFavourites, (allFavourites) =>
    allFavourites.find((favourite) => favourite.id === id)
  );

export const selectFavouriteAdded = (id: string) =>
  createSelector(selectFavouriteById(id), (favourite) => !!favourite);

export const selectFavouritesEntities = createSelector(
  selectFavouritesState,
  (state: FavouritesState) => selectEntities(state)
);

export const selectSelectedId = createSelector(
  selectFavouritesState,
  (state: FavouritesState) => state.selectedId
);

export const selectEntity = createSelector(
  selectFavouritesEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
