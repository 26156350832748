import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { FavouritesState } from '../../../favourites/favourites.reducer';
import { selectFavouriteAdded } from '../../../favourites/favourites.selectors';
import { FavouritesService } from '../../../favourites/favourites.service';

@Component({
  selector: 'snowylive-header',
  standalone: true,
  imports: [CommonModule, IonicModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly favouritesStore = inject(Store<FavouritesState>);
  private readonly favouritesService = inject(FavouritesService);

  @Input() title!: string;
  @Input() icon!: string;
  @Input() href!: string;

  protected isFavorite$$!: Subscription;
  protected isFavorite!: boolean;

  ngOnInit() {
    this.initFavourite();
  }

  ngOnDestroy() {
    this.isFavorite$$.unsubscribe();
  }

  initFavourite() {
    this.isFavorite$$ = this.favouritesStore
      .select(selectFavouriteAdded(this.title))
      .subscribe((isFavorite) => {
        this.isFavorite = isFavorite;
      });
  }

  toggleFavorite(event: Event) {
    event.stopPropagation();
    const item = {
      id: this.title,
      href: this.href,
      icon: this.icon,
    };
    this.favouritesService.addOrRemoveFavorites(item, this.isFavorite);
  }
}
