import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { VersionEventType } from '../update.utils';

@Injectable({ providedIn: 'root' })
export class LogUpdateService {
  constructor(updates: SwUpdate) {
    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case VersionEventType.VERSION_DETECTED:
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case VersionEventType.VERSION_READY:
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${evt.latestVersion.hash}`
          );
          break;
        case VersionEventType.VERSION_INSTALLATION_FAILED:
          console.log(
            `Failed to install app version '${evt.version}': ${evt.error}`
          );
          break;
      }
    });
  }
}
