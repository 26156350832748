import { Document } from '@contentful/rich-text-types';
import { PublicAccessEntries } from '../contentful/contentful';

export enum PublicAccessTitle {
  SafetyAroundTheScheme = 'Safety Around the Scheme',
  PublicAccess = 'Public Access',
  LitteringAndForeshoreUse = 'Littering and Foreshore Use',
  OvernightCamping = 'Overnight Camping',
  Snowy20 = 'Snowy 2.0',
}

export interface IPublicAccess {
  name: PublicAccessTitle;
  href: string;
  icon: string;
  entryId: string;
  content?: Document;
}

export const publicAccess: IPublicAccess[] = [
  {
    name: PublicAccessTitle.SafetyAroundTheScheme,
    href: '/safety-around-the-visit-the-scheme',
    icon: 'warning',
    entryId: PublicAccessEntries.SafetyAroundTheScheme,
  },
  {
    name: PublicAccessTitle.PublicAccess,
    href: '/public-access',
    icon: 'people',
    entryId: PublicAccessEntries.PublicAccess,
  },
  {
    name: PublicAccessTitle.LitteringAndForeshoreUse,
    href: '/littering-and-foreshore-use',
    icon: 'trash',
    entryId: PublicAccessEntries.LitteringAndForeshoreUse,
  },
  {
    name: PublicAccessTitle.OvernightCamping,
    href: '/overnight-camping',
    icon: 'moon',
    entryId: PublicAccessEntries.OvernightCamping,
  },
  {
    name: PublicAccessTitle.Snowy20,
    href: '/snowy-2-0',
    icon: 'snow',
    entryId: PublicAccessEntries.Snowy20,
  },
];

export const noContentString = 'No content';
