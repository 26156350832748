import { Asset, Entry } from 'contentful';
import { Document } from '@contentful/rich-text-types';
import { environment } from '../../environments/environment';

export enum ContentfulContext {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface ContentfulApi {
  name: string;
  space: string;
  host: string;
  accessToken: string;
  environment: string;
}

export const contentfulApis: {
  [name: string]: ContentfulApi;
} = {
  [ContentfulContext.PRODUCTION]: {
    name: ContentfulContext.PRODUCTION,
    environment: ContentfulContext.PRODUCTION,
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.productAccessToken,
    host: environment.contentful.productHost,
  },
  [ContentfulContext.STAGING]: {
    name: ContentfulContext.STAGING,
    environment: ContentfulContext.STAGING,
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.stagingAccessToken,
    host: environment.contentful.stagingHost,
  },
};

export enum ContentfulContentType {
  species = 'speciesPage',
}

export enum InfoEntries {
  infoPage = '2TD5lcQUv8nUAtBrQO8wHo',
  contactUs = '72JuknrN6UdRtNBVuA44kN',
}

export enum PublicAccessEntries {
  SafetyAroundTheScheme = '3A6y0Tt2L8XHwpkgbHZIl3',
  PublicAccess = '1cFbuOao5AUfdNfxzHqGQm',
  LitteringAndForeshoreUse = '6BeBBoF0Ewy4IMvGEHuTqE',
  OvernightCamping = 'OJCa83y4TRwqBeVoaWTgp',
  Snowy20 = '7uf4d6V2PSQdgfPCUjBNYz',
}

export enum SchemeEntries {
  Cabramurra = '67OF3oZ0nzSACMZphbl98q',
  SnowyHydro = '6Fb8OJTRNltz1gx3F0VNfr',
  Talbingo = '1LYmqpmjsAGL3gX4N2jPKW',
  Khancoban = '54TVoxUe3zTXP52GC7QTJ5',
}

export enum DescriptionEntries {
  Favourites = '6WJAnMUAKrSdgj0VlCfEw0',
  VisitTheScheme = '3SNMrpFtSHT6esfQihD8Io',
  PublicAccess = 'CJ7kplgkaw9IBXuIs4po8',
}

export enum BackgroundAssets {
  Home = '7qVHdfSqXfx558vEsRQmJp',
  Blank = '7g9nQZ3ygcz4RsnhMzBWxl',
  WaterReleases = '206oyBBFjLPTSDcqKJvb5b',
  LakeLevels = '18mwwdDGjOEAOEURrWiiCa',
  PublicAccess = 'zLhcsWNKKVWAOGGkCpS7C',
  Others = '5Yl7Ub8Mh2alF0KUDb4Cl4',
  Backup = '4mmY62AgoyQsLEa3iJ9NoT',
}

export interface ISyncCollection {
  entries: Array<Entry<any>>;
  deletedEntries: Array<Entry<any>>;
  assets: Array<Asset>;
  deletedAssets: Array<Asset>;
  nextSyncToken: string;
}

export interface IPublicAccessContent {
  title: string;
  content: Document;
}

export interface IInfoContent {
  content: Document;
}

export interface IDescriptionContent {
  title: string;
  content: Document;
}

export const getAssetFile = (asset: Asset) => {
  const file = asset?.fields?.file;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-prototype-builtins
  return file?.hasOwnProperty('en-US') ? file['en-US'] : file;
};

export const getId = (content: Asset | Entry<any>) => content?.sys.id;

export enum ContentfulSyncStatus {
  Initial = 'Initial',
  UpdateChecked = 'UpdateChecked',
  EntriesSynced = 'EntriesSynced',
  AssetsDownloaded = 'AssetsDownloaded',
  AssetsSynced = 'AssetsSynced',
  Failure = 'Failure',
}

export interface ICacheAsset {
  blob: Blob;
  type: string;
  id: string;
}

export const contentUpdateMessage = `There have been content updates since you last used the app.
                Would you like to download the latest contents now?`;

export enum AppMetadataEntries {
  LatestVersion = '5H0DzpZvzGqZUyXGJufWR9',
}

export interface IAppMetadata {
  metadataName: string;
  value: any;
}
