import { Injectable, inject } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { DB_TABLES } from './db-config';
import { equals, isNil, isNilOrEmpty } from '../ramda-functions';
import { environment } from '../../environments/environment';
import { DBTableName } from './data-config';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { parseString } from 'xml2js';
import { compare } from 'compare-versions';
import { IItemJson } from '../models/Item';

@Injectable({
  providedIn: 'root',
})
export class DBService {
  private storage = inject(Storage);
  private http = inject(HttpClient);

  get = async (key: string) => await this.storage.get(key);
  set = async (key: string, value: any) => await this.storage.set(key, value);
  getValue = async (key: string, value: any) => {
    const data = await this.get(key);
    return data ? data[value] : null;
  };
  create = async () => await this.storage.create();
  clear = async () => await this.storage.clear();

  async initDB() {
    await this.create();
    await this.compareAppVersion();
    await this.initialiseTables();
  }

  compareAppVersion = async () => {
    const appVersion = await this.get(DBTableName.Version);
    if (this.isAppVersionUpdated(appVersion)) {
      await this.clear();
    }
  };

  isAppVersionUpdated = (savedVersion: string) => {
    if (isNil(savedVersion)) {
      return true;
    }
    return compare(environment.version, savedVersion, '>');
  };

  isDataExpired = async (dbTableName: DBTableName) => {
    const cache = await this.get(DBTableName.Cache);

    if (isNil(cache)) {
      return true;
    }

    const lastModified = cache[dbTableName];

    if (isNil(lastModified)) {
      return true;
    }

    const now = new Date();
    const diff = now.getTime() - lastModified.getTime();
    const diffInHours = diff / (1000 * 60); // minutes
    return diffInHours > 5;
  };

  isDataExisted = async (dbTableName: DBTableName) =>
    !isNilOrEmpty(await this.storage.get(dbTableName));

  isDataValid = async (dbTableName: DBTableName) => {
    const isOffline = !navigator.onLine;
    const isDataExisted = await this.isDataExisted(dbTableName);
    const isDataExpired = await this.isDataExpired(dbTableName);
    return isDataExisted && (isOffline || !isDataExpired);
  };

  initialiseTables = async () => {
    for (const table of DB_TABLES) {
      const tableData = await this.get(table.name);
      if (isNilOrEmpty(tableData)) {
        await this.set(table.name, table.data);
      }
    }
  };

  updateCacheLastModified = async (dbTableName: DBTableName) => {
    const cache = await this.get(DBTableName.Cache);
    const updatedCache = {
      ...cache,
      [dbTableName]: new Date(),
    };
    await this.set(DBTableName.Cache, updatedCache);
  };

  fetchData = async (url: string): Promise<IItemJson> => {
    try {
      const response = await firstValueFrom(
        this.http.get(url, { responseType: 'text' })
      );
      return await this.parseXml(response);
    } catch (error: any) {
      const err = new Error('Failed to fetch data: ' + error);
      if (equals(error.status, 404)) {
        err.name = 'DataNotFoundError';
      }
      throw err;
    }
  };

  private parseXml = (xmlData: string): Promise<IItemJson> => {
    return new Promise((resolve, reject) => {
      parseString(xmlData, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };

  generateUrl = (text: string) => {
    return `${environment.dataUrl}/${text}.xml`;
  };
}
