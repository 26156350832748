import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataFetchType, DBTableName } from '../cache/data-config';
import { DBService } from '../cache/db.service';
import {
  IConvertedSnowDepth,
  IGroupedSnowDepths,
  ILatestSnowDepth,
  ISnowDepth,
  ISnowDepthJson,
  ISnowDepthValue,
  SnowDepthObjectOrder,
} from '../models/SnowDepth';
import { ItemDescription, TileType } from '../models/Item';
import {
  FavouriteHref,
  FavouriteTileIcon,
} from '../favourites/favourites.models';
import { updateFavourites } from '../favourites/favourites.actions';
import { Store } from '@ngrx/store';
import { FavouritesState } from '../favourites/favourites.reducer';
import { equals, isNilOrEmpty } from '../ramda-functions';
import { ToastService } from '../shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class SnowDepthsService {
  private dbService = inject(DBService);
  private favouriteStore = inject(Store<FavouritesState>);
  private toastService = inject(ToastService);

  private snowDepths!: ISnowDepth;

  setSnowDepths = (snowDepths: ISnowDepth) => {
    this.snowDepths = snowDepths;
  };

  getSnowDepths = () => this.snowDepths;

  private generateSnowDepthUrl = (year: number) =>
    `${environment.dataUrl}/${DataFetchType.SnowyDepths}${year}.xml`;

  private fetchSnowDepthDataByYear = async (year: number) => {
    const url = this.generateSnowDepthUrl(year);
    return await this.dbService.fetchData(url);
  };

  loadSnowDepthData = async () => {
    if (await this.dbService.isDataValid(DBTableName.SnowDepths)) {
      return this.dbService.get(DBTableName.SnowDepths);
    }
    return this.initSnowDepthData();
  };

  initSnowDepthData = async () => {
    try {
      const thisYear = new Date().getFullYear();
      const formattedSnowDepthsYear = await this.initSnowDepthDataByYear(
        thisYear
      );
      const thisYearHasData = !isNilOrEmpty(formattedSnowDepthsYear);
      const formattedSnowDepthsLastYear = await this.initSnowDepthDataByYear(
        thisYear - 1
      );

      const combinedSnowDepths = this.combineSnowDepths(
        formattedSnowDepthsYear,
        formattedSnowDepthsLastYear,
        thisYearHasData
      );
      await this.updateSnowDepthData(combinedSnowDepths);
      return combinedSnowDepths;
    } catch (error) {
      if (await this.dbService.isDataExisted(DBTableName.SnowDepths)) {
        return this.dbService.get(DBTableName.SnowDepths);
      }
      throw error;
    }
  };

  private updateSnowDepthData = async (data: ISnowDepth) => {
    await this.dbService.set(DBTableName.SnowDepths, data);
    await this.dbService.updateCacheLastModified(DBTableName.SnowDepths);
    this.updateFavourites(data);
  };

  private updateFavourites = (data: ISnowDepth) => {
    const favourites = Object.entries(data).map(([id, snowDepth]) => ({
      id,
      icon: FavouriteTileIcon.snowDepth,
      type: TileType.SnowDepth,
      href: FavouriteHref.snowDepth,
      value: snowDepth.currentValue.value,
      unit: snowDepth.currentValue.unit,
    }));
    this.favouriteStore.dispatch(updateFavourites({ favourites }));
  };

  private initSnowDepthDataByYear = async (
    year: number
  ): Promise<IGroupedSnowDepths> => {
    try {
      const snowDepthData = (await this.fetchSnowDepthDataByYear(
        year
      )) as ISnowDepthJson;
      const filteredSnowDepthData = structuredClone(snowDepthData);
      filteredSnowDepthData.snowyhydro.level =
        snowDepthData.snowyhydro.level.filter((item) =>
          equals(new Date(item.$.date).getFullYear(), year)
        );
      return this.convertSnowDepthData(filteredSnowDepthData);
    } catch (e) {
      const error = e as Error;
      if (equals(error.name, 'DataNotFoundError')) {
        await this.toastService.presentToast(
          `No snow depth data for year ${year}.`
        );
        return {};
      } else {
        throw error;
      }
    }
  };

  private convertSnowDepthData = (snowDepthData: ISnowDepthJson) => {
    const snowDepths = snowDepthData.snowyhydro.level;
    const formattedSnowDepths = snowDepths.flatMap((snowDepth) =>
      snowDepth.snow.map((snowDepth) => ({
        name: snowDepth.$.name,
        date: new Date(snowDepth.$.dataTimestamp),
        quality: snowDepth.$.quality,
        value: parseFloat(snowDepth._),
      }))
    );

    return this.groupSnowDepths(formattedSnowDepths);
  };

  private groupSnowDepths = (snowDepths: IConvertedSnowDepth[]) => {
    const groupedSnowDepths = snowDepths.reduce((acc, snowDepth) => {
      const name = snowDepth.name;
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push({
        date: snowDepth.date,
        quality: snowDepth.quality,
        value: snowDepth.value,
      });
      return acc;
    }, {} as IGroupedSnowDepths);

    return this.sortSnowDepths(groupedSnowDepths);
  };

  private sortSnowDepths = (snowDepths: IGroupedSnowDepths) => {
    const sortedSnowDepthObject: IGroupedSnowDepths = {};
    SnowDepthObjectOrder.forEach((snowDepthName) => {
      sortedSnowDepthObject[snowDepthName] = snowDepths[snowDepthName];
    });
    return sortedSnowDepthObject;
  };

  combineSnowDepths = (
    snowDepthsThisYear: IGroupedSnowDepths,
    snowDepthsLastYear: IGroupedSnowDepths,
    thisYearHasData: boolean
  ): ISnowDepth => {
    return Object.keys(snowDepthsLastYear).reduce(
      (result: ISnowDepth, snowDepthName) => {
        const snowDepthLastYear = snowDepthsLastYear[snowDepthName];
        const snowDepthThisYear = snowDepthsThisYear[snowDepthName] ?? [];
        const latestSnowDepth = this.calculateLatestSnowDepth(
          thisYearHasData ? snowDepthThisYear : snowDepthLastYear
        );
        result[snowDepthName] = {
          snowDepthThisYear: snowDepthThisYear,
          snowDepthLastYear: snowDepthLastYear,
          currentValue: latestSnowDepth,
        };
        return result;
      },
      {}
    );
  };

  private calculateLatestSnowDepth = (
    snowDepth: ISnowDepthValue[]
  ): ILatestSnowDepth => {
    const latestSnowDepth = snowDepth[snowDepth.length - 1];
    return {
      date: latestSnowDepth.date,
      value: latestSnowDepth.value,
      unit: 'cm',
      description: ItemDescription.LatestDepth,
    };
  };
}
