import { inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertService } from '../../alert/alert.service';
import { generateUnrecoverableStateMessage } from '../update.utils';

@Injectable({ providedIn: 'root' })
export class UnrecoverableStateService {
  private alertService = inject(AlertService);
  constructor(updates: SwUpdate) {
    updates.unrecoverable.subscribe(async (event) => {
      await this.alertService.presentDoubleActionAlert(
        'Update error',
        generateUnrecoverableStateMessage(event.reason),
        () => {
          // force clear all localStorage to try to fix the fatal error
          localStorage.clear();
          document.location.reload();
          document.location.href = '/';
        },
        'Refresh',
        undefined,
        true
      );
    });
  }
}
