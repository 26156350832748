<snowylive-header title="Info" icon="information-circle"></snowylive-header>

<ion-content [fullscreen]="true" color="light">
  <ion-card class="detail-card" color="light">
    <ion-card-content>
      <div [innerHTML]="infoRichText"></div>
    </ion-card-content>
  </ion-card>
</ion-content>

<ion-footer>
  <ion-toolbar color="secondary">
    <ion-title slot="start">Version: {{ version }}</ion-title>
    <!--    <ion-buttons slot="end">-->
    <!--      <ion-button (click)="backToTop()">-->
    <!--        <ion-icon slot="icon-only" name="arrow-up"></ion-icon>-->
    <!--      </ion-button>-->
    <!--    </ion-buttons>-->
  </ion-toolbar>
</ion-footer>
