import { createReducer, on, Action } from '@ngrx/store';
import { ContentfulContext, ContentfulSyncStatus } from './contentful';
import {
  assetsDownloaded,
  checkUpdateFailure,
  clearNextSyncToken,
  nextSyncTokenUpdate,
  retry,
  switchContentfulContext,
  syncAssetsFailure,
  syncAssetsSuccess,
  syncEntries,
  syncEntriesFailure,
  syncEntriesSuccess,
  syncSkipped,
  updateAssetsCached,
  updateAssetsDownloaded,
  updateAssetsToCache,
  updateEntriesCached,
  updateEntriesToCache,
} from './contentful.actions';
import { environment } from '../../environments/environment';

export const CONTENTFUL_FEATURE_KEY = 'contentful';

export interface IContentfulState {
  error: string;
  nextSyncToken: string;
  contentfulContext: ContentfulContext;
  viewStatus: ContentfulSyncStatus;
  entriesToCache: number;
  entriesCached: number;
  assetsToCache: number;
  assetsDownloaded: number;
  assetsCached: number;
}

export const initialState: IContentfulState = {
  error: '',
  nextSyncToken: '',
  contentfulContext: environment.contentful.context as ContentfulContext,
  viewStatus: ContentfulSyncStatus.Initial,
  entriesToCache: 0,
  entriesCached: 0,
  assetsToCache: 0,
  assetsDownloaded: 0,
  assetsCached: 0,
};

export const reducer = createReducer(
  initialState,
  on(retry, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.Initial,
    entriesToCache: 0,
    entriesCached: 0,
    assetsToCache: 0,
    assetsDownloaded: 0,
    assetsCached: 0,
  })),
  on(syncEntries, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.UpdateChecked,
  })),
  on(syncEntriesSuccess, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.EntriesSynced,
  })),
  on(assetsDownloaded, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.AssetsDownloaded,
  })),
  on(syncAssetsSuccess, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.AssetsSynced,
  })),
  on(syncSkipped, (state) => ({
    ...state,
    viewStatus: ContentfulSyncStatus.AssetsSynced,
  })),
  on(
    syncAssetsFailure,
    syncEntriesFailure,
    checkUpdateFailure,
    (state, action) => ({
      ...state,
      error: action.error,
      viewStatus: ContentfulSyncStatus.Failure,
    })
  ),
  on(updateEntriesToCache, (state, action) => ({
    ...state,
    entriesToCache: action.entriesToCache,
  })),
  on(updateEntriesCached, (state) => ({
    ...state,
    entriesCached: state.entriesCached + 1,
  })),
  on(updateAssetsToCache, (state, action) => ({
    ...state,
    assetsToCache: action.assetsToCache,
  })),
  on(updateAssetsDownloaded, (state) => ({
    ...state,
    assetsDownloaded: state.assetsDownloaded + 1,
  })),
  on(updateAssetsCached, (state) => ({
    ...state,
    assetsCached: state.assetsCached + 1,
  })),
  on(nextSyncTokenUpdate, (state, action) => ({
    ...state,
    nextSyncToken: action.nextSyncToken,
  })),
  on(clearNextSyncToken, () => initialState),
  on(switchContentfulContext, (state, action) => ({
    ...state,
    contentfulContext: action.contentfulContext,
  }))
);

export function contentfulReducer(
  state: IContentfulState | undefined,
  action: Action
) {
  return reducer(state, action);
}
