import { inject, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';
import { AlertService } from '../../alert/alert.service';
import { Store } from '@ngrx/store';
import { IContentfulState } from '../../contentful/contentful.reducer';
import { clearNextSyncToken } from '../../contentful/contentful.actions';
import { generatePwaUpdateMessage, VersionEventType } from '../update.utils';
import { equals } from '../../ramda-functions';

@Injectable({ providedIn: 'root' })
export class PromptUpdateService {
  private contentfulStore = inject(Store<IContentfulState>);
  private alertService = inject(AlertService);

  constructor(swUpdate: SwUpdate) {
    swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent =>
          equals(evt.type, VersionEventType.VERSION_READY)
        )
      )
      .subscribe(async (evt) => {
        await this.alertService.presentSingleActionAlert(
          'Refresh to update',
          generatePwaUpdateMessage(),
          () => {
            this.contentfulStore.dispatch(clearNextSyncToken());
            document.location.reload();
            document.location.href = '/';
          },
          'Refresh'
        );
      });
  }
}
