import { ElementRef, inject, Injectable } from '@angular/core';
import { LakeLevelsService } from '../lake-levels/lake-levels.service';
import { SnowDepthsService } from '../snow-depths/snow-depths.service';
import { WaterReleasesService } from '../water-releases/water-releases.service';
import { ICurrentReleaseItem, WaterReleaseDam } from '../models/WaterRelease';
import { ContentfulService } from '../contentful/contentful.service';
import {
  Descriptions,
  DescriptionTitle,
  IDescription,
} from '../models/Description';
import { Document } from '@contentful/rich-text-types';
import { PlatformService } from '../shared/services/platform.service';
import { VisitTheSchemeService } from '../visit-the-scheme/visit-the-scheme.service';
import { PublicAccessService } from '../public-access/public-access.service';
import { publicAccess } from '../models/PublicAccess';
import { schemes } from '../models/Scheme';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/swiper-element';
import { AlertService } from '../alert/alert.service';
import { MobileUpdateService } from '../update/mobile/mobile-update.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private lakeLevelsService = inject(LakeLevelsService);
  private snowDepthsService = inject(SnowDepthsService);
  private waterReleasesService = inject(WaterReleasesService);
  private contentfulService = inject(ContentfulService);
  private platformService = inject(PlatformService);
  private visitTheSchemeService = inject(VisitTheSchemeService);
  private publicAccessService = inject(PublicAccessService);
  private alertService = inject(AlertService);
  private mobileUpdateService = inject(MobileUpdateService);

  private descriptions = Descriptions;
  promptEvent: any;
  isAppInstalled = true;

  getIsAppInstalled = () => this.isAppInstalled;

  setIsAppInstalled = (isAppInstalled: boolean) => {
    this.isAppInstalled = isAppInstalled;
  };

  isIOS = () => this.platformService.isIOS();
  isStandAlone = () => this.platformService.isIosPwa();

  setDescriptions = (descriptions: IDescription[]) => {
    this.descriptions = descriptions;
  };

  getDescription = (title: DescriptionTitle) =>
    this.descriptions.find((description) => description.title === title);

  initDescriptions = async (descriptions: IDescription[]) => {
    try {
      const descriptionsWithContent: IDescription[] =
        await this.loadDescriptions(descriptions);
      this.setDescriptions(descriptionsWithContent);
    } catch (error) {
      throw new Error(`Error loading descriptions: ${error}`);
    }
  };

  private loadDescriptions = async (
    descriptions: IDescription[]
  ): Promise<IDescription[]> =>
    Promise.all(
      descriptions.map(async (description) => {
        const descriptionContent = (
          await this.contentfulService.getEntryById(description.entryId)
        ).fields;
        return {
          ...description,
          content: descriptionContent.content as Document,
        };
      })
    );

  loadData = async () => {
    const [lakeLevels, snowDepths, waterReleases] = await Promise.all([
      this.lakeLevelsService.loadLakeLevelsData(),
      this.snowDepthsService.loadSnowDepthData(),
      this.waterReleasesService.loadWaterReleasesData(),
    ]);

    this.lakeLevelsService.setLakeLevels(lakeLevels);
    this.snowDepthsService.setSnowDepths(snowDepths);
    this.waterReleasesService.setWaterReleases(waterReleases);
  };

  loadContentfulData = async () => {
    await this.visitTheSchemeService.initSchemeContents(schemes);
    await this.publicAccessService.initPublicAccessContents(publicAccess);
  };

  getCurrentReleaseLoadedTime = () => {
    const waterReleases = this.waterReleasesService.getWaterReleases();
    const currentRelease = waterReleases[
      WaterReleaseDam.Khancoban
    ] as ICurrentReleaseItem;
    return currentRelease.date;
  };

  initPwaPrompt() {
    if (this.isIOS() && !this.isStandAlone()) {
      this.setIsAppInstalled(false);
    }

    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      this.promptEvent = event;
      // Check if the app is already installed
      this.setIsAppInstalled(false);
    });
  }

  installPwa() {
    if (this.promptEvent && !this.isAppInstalled) {
      this.promptEvent.prompt();
      this.promptEvent.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          // The app was installed
          this.setIsAppInstalled(true);
        }
        this.promptEvent = null;
      });
    }
  }

  initSwiper = (swiperRef: ElementRef<SwiperContainer> | undefined) => {
    if (swiperRef) {
      const swiperEl = swiperRef.nativeElement;
      const swiper = swiperEl.swiper;
      const config: SwiperOptions = {
        slidesPerView: 1,
        init: true,
        enabled: true,
        pagination: {
          enabled: true,
          renderBullet: (index, className) => {
            // hide rendering the first and the last dummy slide
            if (index === 0 || index === swiper.slides.length - 1) {
              return (
                '<span class="' + className + '" style="display: none;"></span>'
              );
            }
            return '<span class="' + className + '"></span>';
          },
        },
        autoplay: true,
        loop: false,
        speed: 400,
      };

      Object.assign(swiperEl, config);
      swiperEl.initialize();

      // if slides is more than 1, then jump to the real slide
      if (swiper.slides.length > 1) {
        swiper.slideTo(1, 0);
      }

      swiper.on('slideChange', () => {
        // Logic to jump to the other side or handle the boundary condition
        if (swiper.isBeginning) {
          swiper.slideTo(swiper.slides.length - 2, 300);
        } else if (swiper.isEnd) {
          swiper.slideTo(1, 300);
        }
      });

      return swiperEl;
    }

    return undefined;
  };
}
