import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { LocalStorageConfig, localStorageSync } from 'ngrx-store-localstorage';
import { persistenceKeys } from './persistence-keys';
import {
  CONTENTFUL_FEATURE_KEY,
  contentfulReducer,
} from '../contentful/contentful.reducer';
import {
  FAVOURITES_FEATURE_KEY,
  favouritesReducer,
} from '../favourites/favourites.reducer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

export const reducers: ActionReducerMap<State> = {
  [CONTENTFUL_FEATURE_KEY]: contentfulReducer,
  [FAVOURITES_FEATURE_KEY]: favouritesReducer,
};

const LOCAL_STORAGE_SYNC_CONFIG: LocalStorageConfig = {
  keys: persistenceKeys,
  rehydrate: true,
  restoreDates: false,
  storageKeySerializer: (key: string) => `snowylive-${key}`,
  storage: localStorage,
};

const localStorageSyncReducer = (
  reducer: ActionReducer<any>
): ActionReducer<any> => {
  return localStorageSync(LOCAL_STORAGE_SYNC_CONFIG)(reducer);
};

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer];
