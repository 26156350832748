import { inject, Injectable } from '@angular/core';
import {
  combineLatest,
  from,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  createClient,
  Entry,
  Asset,
  ContentfulClientApi,
  SyncCollection,
} from 'contentful';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { IContentfulState } from './contentful.reducer';
import { KeepAwake } from '@capacitor-community/keep-awake';
import {
  contentfulApis,
  contentUpdateMessage,
  getAssetFile,
  getId,
  ICacheAsset,
  ISyncCollection,
} from './contentful';
import { isEmpty, isNil, isNilOrEmpty } from '../ramda-functions';
import {
  assetsDownloaded,
  clearNextSyncToken,
  nextSyncTokenUpdate,
  updateAssetsCached,
  updateAssetsDownloaded,
  updateAssetsToCache,
  updateEntriesCached,
  updateEntriesToCache,
} from './contentful.actions';
import { Storage } from '@ionic/storage-angular';
import { AlertService } from '../alert/alert.service';
import {
  selectContentfulContext,
  selectNextSyncToken,
} from './contentful.selectors';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private http = inject(HttpClient);
  private contentfulStore = inject(Store<IContentfulState>);
  private storage = inject(Storage);
  private alertService = inject(AlertService);

  private initClient = (): Observable<ContentfulClientApi> =>
    this.contentfulStore.select(selectContentfulContext).pipe(
      map((contentfulContext) => {
        return contentfulApis[contentfulContext];
      }),
      map(({ accessToken, host, space, environment }) =>
        createClient({
          accessToken: accessToken,
          host: host,
          space: space,
          environment: environment,
        })
      )
    );

  preventScreenSleep = async () => {
    await KeepAwake.keepAwake();
  };

  allowScreenSleep = async () => {
    await KeepAwake.allowSleep();
  };

  presentNotificationIfHasChanges = async (
    syncCollection: ISyncCollection,
    firstLaunch: boolean
  ) => {
    if (
      firstLaunch ||
      (isNilOrEmpty(syncCollection.assets) &&
        isNilOrEmpty(syncCollection.entries))
    ) {
      return Promise.resolve(undefined);
    }

    return new Promise((resolve, reject) => {
      this.alertService.presentDoubleActionAlert(
        'New content!',
        contentUpdateMessage,
        () => {
          this.contentfulStore.dispatch(clearNextSyncToken());
        },
        'Yes',
        reject,
        true
      );
    });
  };

  checkRemoteUpdate = (): Observable<ISyncCollection> => {
    const fetchAllEntriesIfNeedToUpdate = (
      client: ContentfulClientApi,
      syncCollection: SyncCollection
    ) => {
      return !isEmpty(syncCollection.entries)
        ? from(client.getEntries({ limit: 1000 })).pipe(
            map((entries) => ({
              ...syncCollection,
              entries: JSON.parse(entries.stringifySafe()).items,
            }))
          )
        : of(syncCollection);
    };

    const updateEntriesAssetsToDownload = (syncCollection: ISyncCollection) => {
      const entriesToCache = syncCollection.entries.length;
      const assetsToCache = syncCollection.assets.length;
      this.contentfulStore.dispatch(updateEntriesToCache({ entriesToCache }));
      this.contentfulStore.dispatch(updateAssetsToCache({ assetsToCache }));
    };

    const sync = (
      client: ContentfulClientApi,
      nextSyncToken: string
    ): Observable<ISyncCollection> =>
      from(
        client.sync({
          ...(isNilOrEmpty(nextSyncToken)
            ? { initial: true }
            : { nextSyncToken }),
          resolveLinks: false,
        })
      ).pipe(
        map((syncCollection) => ({
          ...JSON.parse(syncCollection.stringifySafe()),
        })),
        switchMap((syncCollection) =>
          fetchAllEntriesIfNeedToUpdate(client, syncCollection)
        ),
        tap(updateEntriesAssetsToDownload)
      );

    return combineLatest([
      this.initClient(),
      this.contentfulStore.select(selectNextSyncToken).pipe(take(1)),
    ]).pipe(
      switchMap(([client, nextSyncToken]) => sync(client, nextSyncToken))
    );
  };

  public syncEntries(
    syncCollection: ISyncCollection
  ): Observable<ISyncCollection> {
    const store = (entries: Array<Entry<any>>): Observable<Array<any>> =>
      combineLatest(
        entries.map((entry) =>
          this.storage
            .set(getId(entry), JSON.stringify(entry))
            .then(() => this.contentfulStore.dispatch(updateEntriesCached()))
        )
      );

    const sync = (): Observable<ISyncCollection> => {
      const { entries, deletedEntries } = syncCollection;
      if (isEmpty(entries) && isEmpty(deletedEntries)) {
        return of(syncCollection);
      }
      const updateEntries = store(entries);
      const deleteEntries = deletedEntries.map((entry) =>
        this.storage.remove(getId(entry))
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return combineLatest([].concat(updateEntries).concat(deleteEntries)).pipe(
        map(() => syncCollection)
      );
    };

    return sync();
  }

  public syncAssets(syncCollection: ISyncCollection): Observable<string> {
    const { assets, deletedAssets } = syncCollection;
    if (isEmpty(assets) && isEmpty(deletedAssets)) {
      return of(syncCollection.nextSyncToken);
    }
    const updateAssets = this.fetchAssets(assets).pipe(
      tap(() => this.contentfulStore.dispatch(assetsDownloaded())),
      switchMap((assetsToCache) => this.cacheAssets(assetsToCache))
    );
    const deleteAssets = deletedAssets.map((asset) =>
      from(this.storage.remove(getId(asset)))
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return combineLatest([].concat(updateAssets).concat(deleteAssets)).pipe(
      map(() => syncCollection.nextSyncToken)
    );
  }

  private fetchAssets(assets: Array<Asset>): Observable<Array<ICacheAsset>> {
    const assetsToFetch = assets
      .filter((asset) => !isNil(getAssetFile(asset)?.url))
      .map((asset) => this.constructAssetUrl(asset));
    return combineLatest(
      assetsToFetch.map((asset) =>
        this.http.get(asset.url, { responseType: 'blob' }).pipe(
          map((blob) => ({ blob, type: asset.type, id: asset.id })),
          tap(() => this.contentfulStore.dispatch(updateAssetsDownloaded()))
        )
      )
    ) as Observable<Array<ICacheAsset>>;
  }

  private cacheAssets(assets: Array<ICacheAsset>) {
    const fileReaderPromise = (blob: Blob, type: string) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        type.includes('image')
          ? reader.readAsDataURL(blob)
          : reader.readAsText(blob);
      });
    return combineLatest(
      assets.map(async (asset) => {
        const result = await fileReaderPromise(asset.blob, asset.type);
        return this.storage
          .set(asset.id, result as string)
          .then(() => this.contentfulStore.dispatch(updateAssetsCached()));
      })
    );
  }

  getEntryById = (entryId: string): Promise<Entry<any>> =>
    this.storage.get(entryId).then((value) => JSON.parse(value));

  getImageAssetById = (assetId: string): Promise<string> =>
    this.storage.get(assetId);

  private constructAssetUrl(asset: Asset): {
    id: string;
    url: string;
    type: string;
  } {
    return {
      id: getId(asset),
      type: getAssetFile(asset)?.contentType as string,
      url: `https:${getAssetFile(asset)?.url}?w=${window.innerWidth * 2}`,
    };
  }

  clearCache = (): Observable<void> =>
    this.initClient().pipe(
      switchMap(() => this.storage.clear()),
      tap(() => this.contentfulStore.dispatch(clearNextSyncToken()))
    );

  updateNextToken = (nextSyncToken: string) => {
    this.contentfulStore.dispatch(nextSyncTokenUpdate({ nextSyncToken }));
  };
}
