export const environment = {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  version: require('../../../../package.json').version,
  dataUrl: 'https://www.snowyhydro.com.au/data/SnowyLIVE',
  contentful: {
    context: 'staging',
    spaceId: 'z5loi28935cy',
    stagingAccessToken: 'TABjUwY-14UI83fo7SSLe9C1rpw0RKFnBer2EXN8fmA',
    productAccessToken: 'adbd3JFrbnieY-QHcmBHcIVtldbIXd8mCEI6XiO-uCg',
    stagingHost: 'preview.contentful.com',
    productHost: 'cdn.contentful.com',
  },

  // sentry related
  targetEnv: 'qa',
};
