import { inject, Injectable } from '@angular/core';
import { FavouritesService } from './favourites.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  updateFavourites,
  updateFavouritesFailure,
  updateFavouritesSuccess,
} from './favourites.actions';
import { catchError, map, of, switchMap } from 'rxjs';

@Injectable()
export class FavouritesEffects {
  private favouritesService = inject(FavouritesService);
  private actions$ = inject(Actions);

  updateFavourites$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateFavourites),
      switchMap(({ favourites }) => {
        return this.favouritesService.updateFavourites(favourites).pipe(
          map((updatedFavourites) => {
            return updateFavouritesSuccess({ favourites: updatedFavourites });
          }),
          catchError((err) => of(updateFavouritesFailure({ error: err })))
        );
      })
    );
  });
}
