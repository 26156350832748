import { createAction, props } from '@ngrx/store';
import { IFavouritesEntity } from './favourites.models';

export const addFavourite = createAction(
  '[Favourites/API] Add Favourite',
  props<{ favourite: IFavouritesEntity }>()
);

export const removeFavourite = createAction(
  '[Favourites/API] Remove Favourite',
  props<{ id: string }>()
);

export const clearFavourites = createAction(
  '[Favourites/API] Clear Favourites'
);

export const updateFavourites = createAction(
  '[Favourites/API] Refresh Favourites',
  props<{ favourites: IFavouritesEntity[] }>()
);

export const updateFavouritesSuccess = createAction(
  '[Favourites/API] Refresh Favourites Success',
  props<{ favourites: IFavouritesEntity[] }>()
);

export const updateFavouritesFailure = createAction(
  '[Favourites/API] Refresh Favourites Failure',
  props<{ error: any }>()
);
