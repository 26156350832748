import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FavouritesState } from './favourites.reducer';
import { IFavouritesEntity } from './favourites.models';
import {
  addFavourite,
  clearFavourites,
  removeFavourite,
} from './favourites.actions';
import { DBService } from '../cache/db.service';
import { ITileItem, TileType } from '../models/Item';
import { LakeLevelsService } from '../lake-levels/lake-levels.service';
import { WaterReleasesService } from '../water-releases/water-releases.service';
import { SnowDepthsService } from '../snow-depths/snow-depths.service';
import { mergeMap, Observable, of } from 'rxjs';
import { concatLatestFrom } from '@ngrx/effects';
import { selectAllFavourites } from './favourites.selectors';
import { equals, not } from '../ramda-functions';

@Injectable({ providedIn: 'root' })
export class FavouritesService {
  private favouriteStore = inject(Store<FavouritesState>);
  private dbService = inject(DBService);
  private lakeLevelsService = inject(LakeLevelsService);
  private waterReleasesService = inject(WaterReleasesService);
  private snowDepthsService = inject(SnowDepthsService);

  addFavourite = (favourite: IFavouritesEntity) => {
    this.favouriteStore.dispatch(addFavourite({ favourite }));
  };

  removeFavourite = (id: string) => {
    this.favouriteStore.dispatch(removeFavourite({ id }));
  };

  clearFavourites = () => {
    this.favouriteStore.dispatch(clearFavourites());
  };

  addOrRemoveFavorites = (item: IFavouritesEntity, isFavorite: boolean) => {
    if (isFavorite) {
      this.removeFavourite(item.id);
    } else {
      this.addFavourite(item);
    }
  };

  updateFavourites(
    favourites: IFavouritesEntity[]
  ): Observable<IFavouritesEntity[]> {
    return of(favourites).pipe(
      concatLatestFrom(() => this.favouriteStore.select(selectAllFavourites)),
      mergeMap(([updatedFavourites, existingFavourites]) => {
        // Create a set of existing favorite ids
        const existingFavoritesIdSet = new Set(
          existingFavourites.map((item) => item.id)
        );

        const displayedFavourites = updatedFavourites
          .map((updatedItem) =>
            // reconstruct the href for items that have the details view
            not(
              equals(updatedItem.type, TileType.WaterRelease) ||
                equals(updatedItem.type, TileType.CurrentRelease)
            )
              ? {
                  ...updatedItem,
                  href: updatedItem.href + '/' + updatedItem.id,
                }
              : updatedItem
          )
          // Filter out the items that are not previously existed
          .filter((updatedItem) => existingFavoritesIdSet.has(updatedItem.id));

        // return the filtered values that need to be updated
        return of(displayedFavourites);
      })
    );
  }

  generateFavouriteItemValue = (
    item: ITileItem
  ): Partial<IFavouritesEntity> => {
    if ('currentValue' in item) {
      return {
        value: item.currentValue.value,
        unit: item.currentValue.unit,
        growthStatus:
          'growthStatus' in item.currentValue
            ? item.currentValue.growthStatus
            : undefined,
      };
    }

    return {
      value: item.value,
      unit: item.unit,
      growthStatus: item.growthStatus,
    };
  };
}
