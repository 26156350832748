import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../shared/ui/header/header.component';
import { IonicModule } from '@ionic/angular';
import { ContentfulService } from '../contentful/contentful.service';
import { IInfoContent, InfoEntries } from '../contentful/contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  selector: 'snowylive-contact-us',
  standalone: true,
  imports: [CommonModule, HeaderComponent, IonicModule],
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  private contentfulService = inject(ContentfulService);
  private cdr = inject(ChangeDetectorRef);

  private contactUsContentId = InfoEntries.contactUs;
  contactUsContent!: IInfoContent;
  contactUsRichText!: string;

  async ngOnInit() {
    this.contactUsContent = (
      await this.contentfulService.getEntryById(this.contactUsContentId)
    ).fields;
    this.contactUsRichText = documentToHtmlString(
      this.contactUsContent.content
    );
    this.cdr.detectChanges();
  }
}
