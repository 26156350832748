import {
  ICurrentLakeLevel,
  ILakeLevel,
  ILakeLevelItem,
  ILakeLevelJson,
} from './LakeLevel';
import {
  ILatestSnowDepth,
  ISnowDepth,
  ISnowDepthJson,
  ISnowDepthObject,
} from './SnowDepth';
import {
  ICurrentReleaseItem,
  IWaterRelease,
  IWaterReleaseItem,
  IWaterReleaseJson,
  IWaterReleaseValue,
} from './WaterRelease';
import { Document } from '@contentful/rich-text-types';

export interface IMenuItem {
  name: string;
  href: string;
  icon?: string;
  image?: string;
}

export enum GrowthStatus {
  'GROWING' = 'trending-up-outline',
  'FALLING' = 'trending-down-outline',
  'STABLE' = 'custom-stable',
}

export enum ItemDescription {
  CurrentStorage = 'Current Storage',
  LatestDepth = 'Latest Depth',
}

export enum TileType {
  LakeLevel = 'lakeLevel',
  SnowDepth = 'snowDepth',
  WaterRelease = 'waterRelease',
  CurrentRelease = 'currentRelease',
}

export type IItem = IWaterRelease | ILakeLevel | ISnowDepth;

export type ITileItem =
  | ILakeLevelItem
  | ISnowDepthObject
  | IWaterReleaseItem
  | ICurrentReleaseItem;

export type IDisplayedItem =
  | ICurrentLakeLevel
  | ILatestSnowDepth
  | IWaterReleaseValue
  | ICurrentReleaseItem;

export type IItemJson = ILakeLevelJson | ISnowDepthJson | IWaterReleaseJson;

export interface ITile {
  name: string;
  value: ITileItem;
}
