import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';
import { environment } from './environments/environment';

Sentry.init(
  {
    dsn: 'https://af65d3b66b48c47d1fa4bef6ed24a206@o264253.ingest.sentry.io/4506532654219264',
    // release version
    release: `${environment.version}`,
    // separate environments for error logging
    environment: environment.targetEnv,

    integrations: [
      // disable trace on fetch(get) requests to avoid extra header in requests
      new SentryAngular.BrowserTracing({
        traceFetch: false,
      }),
    ],

    // Fix issue: Native Client is not available, can't start on native
    // Refer: https://github.com/getsentry/sentry-react-native/issues/920
    // we can disable it safely because it is not a native app
    enableNative: false,
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error('Error bootstrapping application:', err)
);
