import { inject, Injectable } from '@angular/core';
import { DataFetchType, DBTableName } from '../cache/data-config';
import { DBService } from '../cache/db.service';
import {
  IConvertedLakeLevel,
  IGroupedLakeLevel,
  ILakeLevel,
  ILakeLevelJson,
  ILakeLevelJsonItem,
  ILakeLevelValue,
  LakeLevelBoundary,
} from '../models/LakeLevel';
import { GrowthStatus, ItemDescription, TileType } from '../models/Item';
import { Store } from '@ngrx/store';
import { FavouritesState } from '../favourites/favourites.reducer';
import { updateFavourites } from '../favourites/favourites.actions';
import {
  FavouriteHref,
  FavouriteTileIcon,
} from '../favourites/favourites.models';
import { equals } from '../ramda-functions';
import { ToastService } from '../shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class LakeLevelsService {
  private dbService = inject(DBService);
  private favouriteStore = inject(Store<FavouritesState>);
  private toastService = inject(ToastService);

  private lakeLevels!: ILakeLevel;

  setLakeLevels = (lakeLevels: ILakeLevel) => {
    this.lakeLevels = lakeLevels;
  };

  getLakeLevels = () => this.lakeLevels;

  private generateLakeLevelsUrl = () =>
    this.dbService.generateUrl(DataFetchType.LakeLevels);

  fetchLakeLevelsData = async () => {
    const url = this.generateLakeLevelsUrl();
    try {
      return await this.dbService.fetchData(url);
    } catch (e) {
      const error = e as Error;
      if (equals(error.name, 'DataNotFoundError')) {
        await this.toastService.presentToast(`No lake levels data found.`);
        return {};
      } else {
        throw error;
      }
    }
  };

  loadLakeLevelsData = async () => {
    if (await this.dbService.isDataValid(DBTableName.LakeLevels)) {
      return this.dbService.get(DBTableName.LakeLevels);
    }
    return this.initLakeLevelsData();
  };

  initLakeLevelsData = async () => {
    try {
      const lakeLevelsData =
        (await this.fetchLakeLevelsData()) as ILakeLevelJson;
      const formattedLakeLevels = this.convertLakeLevelData(lakeLevelsData);
      await this.updateLakeLevelsData(formattedLakeLevels);
      return formattedLakeLevels;
    } catch (error) {
      if (await this.dbService.isDataExisted(DBTableName.LakeLevels)) {
        return this.dbService.get(DBTableName.LakeLevels);
      }
      throw error;
    }
  };

  private updateLakeLevelsData = async (data: ILakeLevel) => {
    await this.dbService.set(DBTableName.LakeLevels, data);
    await this.dbService.updateCacheLastModified(DBTableName.LakeLevels);
    this.updateFavourite(data);
  };

  private updateFavourite = (data: ILakeLevel) => {
    const favourites = Object.entries(data).map(([id, lakeLevel]) => ({
      id,
      icon: FavouriteTileIcon.lakeLevel,
      type: TileType.LakeLevel,
      href: FavouriteHref.LakeLevel,
      value: lakeLevel.currentValue.value,
      unit: lakeLevel.currentValue.unit,
      growthStatus: lakeLevel.currentValue.growthStatus,
    }));
    this.favouriteStore.dispatch(updateFavourites({ favourites }));
  };

  convertLakeLevelData = (rawJsonData: ILakeLevelJson) => {
    const lakeLevels = rawJsonData.snowyhydro.level;
    const formattedLakeLevels = lakeLevels.flatMap(
      (lakeLevel: ILakeLevelJsonItem) => {
        return lakeLevel.lake.map((lake) => ({
          name: lake.$.name,
          date: new Date(lake.$.dataTimeStamp),
          level: parseFloat(lake._),
        }));
      }
    );

    const structuredLakeLevels = this.groupLakeLevels(formattedLakeLevels);
    return this.addCurrentLakeLevel(structuredLakeLevels);
  };

  private groupLakeLevels = (lakeLevels: IConvertedLakeLevel[]) => {
    return lakeLevels.reduce((acc, lakeLevel) => {
      if (!acc[lakeLevel.name]) {
        acc[lakeLevel.name] = [];
      }
      acc[lakeLevel.name].push({
        date: lakeLevel.date,
        value: lakeLevel.level,
      });
      return acc;
    }, {} as IGroupedLakeLevel);
  };

  addCurrentLakeLevel = (lakeLevels: IGroupedLakeLevel): ILakeLevel => {
    return Object.keys(lakeLevels).reduce(
      (result: ILakeLevel, lakeLevelName) => {
        const lakeLevel = lakeLevels[lakeLevelName];
        const currentLakeLevel = this.calculateCurrentLakeLevel(
          lakeLevel,
          lakeLevelName
        );
        result[lakeLevelName] = {
          currentValue: currentLakeLevel,
          values: lakeLevel,
        };
        return result;
      },
      {}
    );
  };

  calculateCurrentLakeLevel = (
    lakeLevel: ILakeLevelValue[],
    lakeLevelName: string
  ) => {
    const currentLakeLevel = lakeLevel[lakeLevel.length - 1];
    const previousLakeLevel = lakeLevel[lakeLevel.length - 2];
    const growthStatus = this.calculateGrowthStatus(
      currentLakeLevel,
      previousLakeLevel,
      LakeLevelBoundary[lakeLevelName]
    );
    return {
      date: currentLakeLevel.date,
      value: currentLakeLevel.value,
      unit: '%',
      growthStatus,
      description: ItemDescription.CurrentStorage,
    };
  };

  calculateGrowthStatus = (
    currentLakeLevel: ILakeLevelValue,
    previousLakeLevel: ILakeLevelValue,
    boundary: number
  ) => {
    const changeValue = currentLakeLevel.value - previousLakeLevel.value;
    const compareToRange = Math.abs(changeValue) > boundary;

    if (changeValue > 0 && compareToRange) {
      return GrowthStatus.GROWING;
    }
    if (changeValue < 0 && compareToRange) {
      return GrowthStatus.FALLING;
    }
    return GrowthStatus.STABLE;
  };
}
