import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CONTENTFUL_FEATURE_KEY, IContentfulState } from './contentful.reducer';
import { ContentfulSyncStatus } from './contentful';
import { includes, not } from '../ramda-functions';

export const selectContentfulState = createFeatureSelector<IContentfulState>(
  CONTENTFUL_FEATURE_KEY
);

export const selectError = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.error
);

export const selectViewStatus = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.viewStatus
);

export const selectIsSyncing = createSelector(
  selectViewStatus,
  (viewStatus: ContentfulSyncStatus) =>
    not(
      includes(viewStatus, [
        ContentfulSyncStatus.Initial,
        ContentfulSyncStatus.AssetsSynced,
        ContentfulSyncStatus.Failure,
      ])
    )
);

export const selectEntriesToDownload = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.entriesToCache
);

export const selectEntriesDownloaded = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.entriesCached
);

export const selectAssetsToDownload = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.assetsToCache
);

export const selectAssetsDownloaded = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.assetsDownloaded
);

export const selectAssetsCached = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.assetsCached
);

export const selectNextSyncToken = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.nextSyncToken
);

export const selectContentfulContext = createSelector(
  selectContentfulState,
  (state: IContentfulState) => state.contentfulContext
);
