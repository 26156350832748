import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  OnInit,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Router, RouterModule } from '@angular/router';
import { DBService } from './cache/db.service';
import { CheckForUpdateService } from './update/pwa/check-update.service';
import { LogUpdateService } from './update/pwa/log-update.service';
import { PromptUpdateService } from './update/pwa/prompt-update.service';
import { UnrecoverableStateService } from './update/pwa/unrecoverable-state.service';

@Component({
  standalone: true,
  imports: [RouterModule, IonicModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'snowylive-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private dbService = inject(DBService);
  private router = inject(Router);

  /*
   * Injecting PWA update services
   */
  protected checkForUpdateService = inject(CheckForUpdateService);
  protected logUpdateService = inject(LogUpdateService);
  protected promptUpdateService = inject(PromptUpdateService);
  protected uncoverableStateService = inject(UnrecoverableStateService);

  async ngOnInit() {
    await this.dbService.initDB();
    await this.router.navigateByUrl('sync');
  }
}
