import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as FavouritesActions from './favourites.actions';
import { IFavouritesEntity } from './favourites.models';

export const FAVOURITES_FEATURE_KEY = 'favourites';

export interface FavouritesState extends EntityState<IFavouritesEntity> {
  selectedId?: string | number; // which Favourites record has been selected
  error?: string | null; // last known error (if any)
}

export interface FavouritesPartialState {
  readonly [FAVOURITES_FEATURE_KEY]: FavouritesState;
}

export const favouritesAdapter: EntityAdapter<IFavouritesEntity> =
  createEntityAdapter<IFavouritesEntity>();

export const initialFavouritesState: FavouritesState =
  favouritesAdapter.getInitialState({});

const reducer = createReducer(
  initialFavouritesState,
  on(FavouritesActions.addFavourite, (state, { favourite }) =>
    favouritesAdapter.addOne(favourite, state)
  ),
  on(FavouritesActions.removeFavourite, (state, { id }) =>
    favouritesAdapter.removeOne(id, state)
  ),
  on(FavouritesActions.clearFavourites, (state) =>
    favouritesAdapter.removeAll(state)
  ),
  on(FavouritesActions.updateFavouritesSuccess, (state, { favourites }) =>
    favouritesAdapter.upsertMany(favourites, state)
  ),
  on(FavouritesActions.updateFavouritesFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function favouritesReducer(
  state: FavouritesState | undefined,
  action: Action
) {
  return reducer(state, action);
}
