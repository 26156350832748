export enum DBTableName {
  LakeLevels = 'lakeLevels',
  SnowDepths = 'snowDepths',
  WaterReleases = 'waterReleases',
  Cache = 'cache',
  Version = 'version',
}

export interface IValidationPeriod {
  [key: string]: DataValidationPeriod;
}
export enum DataValidationPeriod {
  Daily = 1,
  HalfDaily = 0.5,
  realtime = 0,
}

export const DataValidationPeriods: IValidationPeriod = {
  [DBTableName.LakeLevels]: DataValidationPeriod.Daily,
  [DBTableName.SnowDepths]: DataValidationPeriod.Daily,
  [DBTableName.WaterReleases]: DataValidationPeriod.HalfDaily,
};

export enum DataFetchType {
  LakeLevels = 'LakeLevels',
  SnowyDepths = 'SnowyDepths',
  CaravanCampingParks = 'CaravanCampingParks',
  SHLFacilities = 'SHLFacilities',
  Jindabyne = 'WaterReleasesJindabyne',
  Tantangara = 'WaterReleasesTantangara',
  Khancoban = 'DamCurrentReleases',
}
