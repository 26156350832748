import { TileType } from '../models/Item';

/**
 * Interface for the 'Favourites' data
 */
export interface IFavouritesEntity {
  id: string;
  icon: string;
  href: string;
  value?: number;
  unit?: string;
  growthStatus?: string;
  type?: TileType;
}

export enum FavouriteHref {
  LakeLevel = '/lake-levels/lake-level-history/',
  publicAccess = '/public-access/details/',
  snowDepth = '/snow-depths/snow-depth-history/',
  visitTheScheme = '/visit-the-scheme/details/',
  waterRelease = '/water-releases',
}

export enum FavouriteTileIcon {
  lakeLevel = 'water',
  snowDepth = 'snow',
  waterRelease = 'custom-water-release',
  pubicAccess = 'boat',
  visitTheScheme = 'car',
}
