import { environment } from '../../environments/environment';
import { compareVersions } from 'compare-versions';

export const getCurrentAppVersion = async () => {
  return new Promise<string>(environment.version);
};

export const generateUnrecoverableStateMessage = (reason: string) => {
  return `An error occurred that we cannot recover from: ${reason}.
  Please reload the page and contact support if the problem persists.`;
};

export const generatePwaUpdateMessage = () => {
  return 'Application update detected. Please refresh to apply the latest changes.';
};

export const isVersionGreaterThan = (
  version1: string,
  version2: string
): boolean => {
  return compareVersions(version1, version2) > 0;
};

export const isVersionLessThan = (
  version1: string,
  version2: string
): boolean => {
  return compareVersions(version1, version2) < 0;
};

export const isVersionEqual = (version1: string, version2: string): boolean => {
  return compareVersions(version1, version2) === 0;
};

export const enum VersionEventType {
  VERSION_DETECTED = 'VERSION_DETECTED',
  VERSION_READY = 'VERSION_READY',
  VERSION_INSTALLATION_FAILED = 'VERSION_INSTALLATION_FAILED',
}
