import { GrowthStatus, ItemDescription } from './Item';
import { ChartOptions } from 'chart.js';

export const LakeLevelBoundary: IBoundary = {
  'Lake Eucumbene': 0.1,
  'Lake Jindabyne': 0.1,
  'Tantangara Reservoir': 0.1,
};

export enum LakeLevelLake {
  Eucumbene = 'Lake Eucumbene',
  Jindabyne = 'Lake Jindabyne',
  Tantangara = 'Tantangara Reservoir',
}

export interface IBoundary {
  [key: string]: number;
}

export interface ILakeLevel {
  [key: string]: ILakeLevelItem;
}

export interface ILakeLevelItem {
  currentValue: ICurrentLakeLevel;
  values: ILakeLevelValue[];
}

export interface ILakeLevelValue {
  date: Date;
  value: number;
}

export interface ICurrentLakeLevel {
  date: Date;
  value: number;
  unit: string;
  growthStatus: GrowthStatus;
  description: ItemDescription;
}

export interface ILakeLevelJson {
  snowyhydro: {
    level: ILakeLevelJsonItem[];
  };
}

export interface ILakeLevelJsonItem {
  $: {
    date: string;
  };
  lake: ILakeLevelJsonItemLake[];
}

export interface ILakeLevelJsonItemLake {
  $: {
    name: string;
    dataTimeStamp: string;
  };
  _: string;
}

export interface IConvertedLakeLevel {
  name: string;
  date: Date;
  level: number;
}

export interface IGroupedLakeLevel {
  [key: string]: ILakeLevelValue[];
}

export const lakeLevelHistoryChartOptions: ChartOptions = {
  parsing: {
    xAxisKey: 'date',
    yAxisKey: 'value',
  },
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 100,
      ticks: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        callback: (value: number) => `${value}%`,
      },
    },
    x: {
      type: 'timeseries',
      time: {
        unit: 'month',
        displayFormats: {
          month: 'dd MMM yy',
        },
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 4,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

export const lakeLevelsChartDataConfig = {
  label: 'Lake Level',
  borderWidth: 1,
  borderColor: 'rgba(51, 150, 197, 1)',
  backgroundColor: 'rgba(51, 150, 197, 0.2)',
};
