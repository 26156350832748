import { DBTableName } from './data-config';
import { environment } from '../../environments/environment';

export const DB_TABLES = [
  {
    name: DBTableName.Version,
    data: environment.version,
  },
  {
    name: DBTableName.Cache,
    data: {
      lakeLevels: null,
      snowDepths: null,
      visitTheScheme: null,
      publicAccess: null,
      waterReleases: null,
      favourites: null,
    },
  },
  {
    name: DBTableName.LakeLevels,
    data: {},
  },
  {
    name: DBTableName.SnowDepths,
    data: {},
  },
  {
    name: DBTableName.WaterReleases,
    data: {},
  },
];
