import { Document } from '@contentful/rich-text-types';
import { SchemeEntries } from '../contentful/contentful';
import { Asset } from 'contentful';

export interface IScheme {
  name: SchemeTitle;
  icon: string;
  href: string;
  entryId: SchemeEntries;
  title?: string;
  content?: Document;
  coverImage?: string;
  detailImages?: string[];
}

export interface ISchemeContent {
  title: string;
  description: Document;
  coverImage: Asset;
  detailImages: Asset[];
}

export enum SchemeTitle {
  Cabramurra = 'Cabramurra Visitor Centre and General Store',
  SnowyHydro = 'Snowy Hydro Discovery Centre',
  Talbingo = 'Talbingo Visitor Display',
  Khancoban = 'Khancoban Visitor Information Centre',
}

export enum SchemeHref {
  Cabramurra = '/cabramurra',
  SnowyHydro = '/snowy-hydro',
  Talbingo = '/talbingo',
  Khancoban = '/khancoban',
}

export const schemes: IScheme[] = [
  {
    name: SchemeTitle.Cabramurra,
    entryId: SchemeEntries.Cabramurra,
    href: SchemeHref.Cabramurra,
    icon: 'car',
  },
  {
    name: SchemeTitle.SnowyHydro,
    entryId: SchemeEntries.SnowyHydro,
    href: SchemeHref.SnowyHydro,
    icon: 'car',
  },
  {
    name: SchemeTitle.Talbingo,
    entryId: SchemeEntries.Talbingo,
    href: SchemeHref.Talbingo,
    icon: 'car',
  },
  {
    name: SchemeTitle.Khancoban,
    entryId: SchemeEntries.Khancoban,
    href: SchemeHref.Khancoban,
    icon: 'car',
  },
];
