import { inject, Injectable } from '@angular/core';
import { ContentfulService } from '../contentful/contentful.service';
import { IScheme, ISchemeContent, schemes } from '../models/Scheme';
import { equals } from '../ramda-functions';
import { IMenuItem } from '../models/Item';
import { getId } from '../contentful/contentful';
import { Asset, Entry } from 'contentful';
import { isNil } from 'rambda';
import { DBService } from '../cache/db.service';
import { Store } from '@ngrx/store';
import { IContentfulState } from '../contentful/contentful.reducer';
import { clearNextSyncToken } from '../contentful/contentful.actions';

@Injectable({
  providedIn: 'root',
})
export class VisitTheSchemeService {
  private contentfulService = inject(ContentfulService);
  private dbService = inject(DBService);
  private contentfulStore = inject(Store<IContentfulState>);

  private visitTheSchemeContents = schemes;

  getSchemeContents = () => this.visitTheSchemeContents;

  setSchemeContents = (schemeContents: IScheme[]) => {
    this.visitTheSchemeContents = schemeContents;
  };

  getSchemeContent = (name: string) => {
    return this.visitTheSchemeContents.find((schemeContent) =>
      equals(schemeContent.title, name)
    );
  };

  initSchemeContents = async (schemeContents: IScheme[]) => {
    try {
      const schemeContentsWithContent: IScheme[] =
        (await this.loadSchemeContents(schemeContents)) as any;
      this.setSchemeContents(schemeContentsWithContent);
    } catch (error) {
      console.error(error);
      // force clear the NextSyncToken that is causing the error in new version app
      this.contentfulStore.dispatch(clearNextSyncToken());
    }
  };

  private loadSchemeContents = async (contents: IScheme[]) => {
    return Promise.all(
      contents.map(async (content) => {
        const schemeContent = (
          await this.contentfulService.getEntryById(content.entryId)
        ).fields;

        const coverImage = await this.loadImage(schemeContent.coverImage);
        const detailImages = await this.loadImages(schemeContent.detailImages);

        return {
          ...content,
          content: schemeContent.description as Document,
          title: schemeContent.title,
          coverImage,
          detailImages,
        };
      })
    );
  };

  private loadImage = async (image: Asset) => {
    const coverImageId = getId(image);
    if (isNil(coverImageId)) {
      return;
    }
    return await this.dbService.get(coverImageId);
  };

  private loadImages = async (images: Asset[]) => {
    return Promise.all(images.map((image) => this.loadImage(image)));
  };

  generateMenuItems = (): IMenuItem[] =>
    this.getSchemeContents().map(({ title, href, coverImage }) => ({
      name: title as string,
      href,
      image: coverImage,
    }));
}
