<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons *ngIf="title" slot="start">
      <ion-back-button color="light" defaultHref="/home"></ion-back-button>
    </ion-buttons>

    <ion-title color="light">
      {{ title }}
      <ion-icon *ngIf="!href" [name]="icon"></ion-icon>
    </ion-title>

    <ion-buttons slot="end" *ngIf="href">
      <ion-button (click)="toggleFavorite($event)">
        <ion-icon
          [name]="isFavorite ? 'star' : 'star-outline'"
          [color]="isFavorite ? 'warning' : 'light'"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
